import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import clsx from "clsx";
import Icon, { IconName } from "@/components/Icons";

export type ButtonProps = PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    size?: "sm" | "lg";
    mode?: "primary" | "transparent" | "default" | "danger";
    fullWidth?: boolean;
    loading?: boolean;
    circle?: boolean;
    icon?: IconName;
    iconPosition?: "left" | "right";
  };

const Button = ({
  children,
  className,
  disabled = false,
  fullWidth = false,
  loading = false,
  circle = false,
  size = "lg",
  mode = "primary",
  icon,
  iconPosition = "left",
  ...rest
}: ButtonProps) => {
  const classes = clsx(
    `
    flex
    items-center
    justify-center
    transition-all
    disabled:bg-light-utility-low
    disabled:dark:bg-dark-utility-low
    text-base
    font-medium
    group
    gap-1
    `,
    {
      "rounded-xl": !circle,
      "h-11 px-5 py-2.5": size === "lg" && !circle,
      "h-9 px-3 py-2": size === "sm" && !circle,
    },
    {
      "bg-light-brand-primary dark:bg-dark-brand-primary text-light-source-white dark:text-dark-source-white hover:bg-light-brand-secondary hover:dark:bg-dark-brand-secondary":
        mode === "primary",
      "bg-light-brand-alpha dark:bg-dark-brand-alpha text-light-brand-primary dark:text-dark-brand-primary hover:bg-light-brand-primary hover:dark:bg-dark-brand-primary hover:text-light-source-white dark:hover:text-dark-source-white":
        mode === "transparent",
      "bg-light-surface-medium dark:bg-dark-surface-medium hover:bg-light-surface-low hover:dark:bg-dark-surface-low text-light-utility-medium dark:text-dark-utility-medium":
        mode === "default",
      "bg-light-source-error dark:bg-dark-source-error text-light-source-white text-dark-source-white":
        mode === "danger",
    },
    {
      "w-full": fullWidth,
    },
    {
      "h-9 w-9": circle && size === "sm",
      "h-11 w-11": circle && size === "lg",
      "rounded-full": circle,
    },
    className
  );

  const iconClasses = clsx({
    "fill-light-brand-primary dark:fill-dark-brand-primary group-hover:fill-light-source-white dark:group-hover:fill-dark-source-white":
      mode === "transparent",
    "fill-light-utility-medium dark:fill-dark-utility-medium":
      mode === "default",
  });

  return (
    <button className={classes} disabled={loading || disabled} {...rest}>
      {!loading && icon && iconPosition === "left" && (
        <Icon name={icon} className={iconClasses} />
      )}
      {loading && iconPosition === "left" && (
        <Icon name="spinner" className="animate-spin h-5 w-5 text-white" />
      )}
      {children}
      {loading && iconPosition === "right" && (
        <Icon name="spinner" className="animate-spin h-5 w-5 text-white" />
      )}
      {!loading && icon && iconPosition === "right" && (
        <Icon name={icon} className={iconClasses} />
      )}
    </button>
  );
};

export default Button;
