import { Link, useLocation } from "react-router-dom";
import Icon, { IconName } from "../Icons";
import clsx from "clsx";

type NavigationItemProps = {
  name: string
  to: string;
  icon: IconName;
  count?: number;
};

const NavigationItem = ({ to, name, icon, count }: NavigationItemProps) => {
  const { pathname } = useLocation();
  const isActive = pathname.split("/")[1] === to.split("/")[1];

  return (
    <div className="py-3">
      <Link to={to} className="flex items-center gap-3">
        <Icon
          name={icon}
          className={clsx({
            "fill-light-brand-primary dark:fill-dark-brand-primary": isActive,
            "fill-light-utility-medium dark:fill-dark-utility-medium":
              !isActive,
          })}
        />

        <span
          className={clsx({
            "text-light-utility-medium dark:text-dark-utility-medium":
              !isActive,
            "text-light-brand-primary dark:text-dark-brand-primary": isActive,
          })}
        >
          {name}
        </span>

        {count && (
          <div
            className={clsx("bg-light-source-error dark:bg-dark-source-error h-5 w-5 rounded-full flex justify-center items-center text-white text-base")}
          >
            {count}
          </div>
        )}
      </Link>
    </div>
  );
};

export default NavigationItem;
