import MainLayout from "@/components/Layouts/MainLayout";
import AuthLayout from "@/components/Layouts/AuthLayout";
import ProtectedRoute from "@/components/Routes/ProtectedRoute";
import NonProtectedRoute from "@/components/Routes/NonProtectedRoute";
import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/auth/login";
import { lazy } from "react";
import LayoutProivders from "./providers";

const AdministartionsPage = lazy(() => import("./pages/administartions"));
const FilesPage = lazy(() => import("./pages/files"));
const AdminSettingsPage = lazy(() => import("./pages/admin-settings"));
const VersionPage = lazy(() => import("./pages/version"));
const StoriesPage = lazy(() => import("./pages/stories"));
const RegisterPage = lazy(() => import("./pages/auth/register"));
const EmailVerification = lazy(() => import("./pages/auth/email-verification"));
const ForgotPasswordPage = lazy(() => import("./pages/auth/forgot-password"));
const ResetPasswordPage = lazy(() => import("./pages/auth/reset-password"));
const CreatePage = lazy(() => import("./pages/create"));
const CreateQuizPage = lazy(() => import("./pages/create/Quiz"));
const BalancePage = lazy(() => import("./pages/balance"));
const SettingsPasswordsPage = lazy(() => import("./pages/settings/passwords"));
const SettingsAccountPage = lazy(() => import("./pages/settings/account"));
const SettingsOthersPage = lazy(() => import("./pages/settings/others"));
const SettingsPage = lazy(() => import("./pages/settings"));
const UsersPage = lazy(() => import("./pages/customers"));
const ReportsPage = lazy(() => import("./pages/reports"));
const HomePage = lazy(() => import("./pages/home"));
const SubscriptionQuizesPage = lazy(() => import("./pages/subscripion-quizes"));
const SubscriptionQuizesCreatePage = lazy(() => import("./pages/subscripion-quizes/subscription-quiz-create"));
const SponsorQuizPage = lazy(() => import("./pages/create/sponsor-quiz"));

const SettingsNotificationPage = lazy(
  () => import("./pages/settings/notification")
);

const routes = createBrowserRouter([
  {
    element: <LayoutProivders />,
    children: [
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <HomePage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/users",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <UsersPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/reports",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <ReportsPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/management",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <AdministartionsPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/files",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <FilesPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: '/subscription-quizes',
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute allowedRoles={['SUPER_ADMIN']}>
                <MainLayout>
                  <SubscriptionQuizesPage />
                </MainLayout>
              </ProtectedRoute>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoute allowedRoles={['SUPER_ADMIN']}>
                <MainLayout>
                  <SubscriptionQuizesCreatePage />
                </MainLayout>
              </ProtectedRoute>
            )
          }
        ]
      },
      // {
      //   path: "/admin-settings",
      //   element: (
      //     <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
      //       <MainLayout>
      //         <AdminSettingsPageLegacy />
      //       </MainLayout>
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: "/admin-settings",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <AdminSettingsPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/versions",
        element: (
          <ProtectedRoute allowedRoles={["SUPER_ADMIN"]}>
            <MainLayout>
              <VersionPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/stories",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <StoriesPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "balance",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <BalancePage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/settings",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <SettingsPage />
            </MainLayout>
          </ProtectedRoute>
        ),

        children: [
          {
            path: "account",
            element: <SettingsAccountPage />,
          },
          {
            path: "password",
            element: <SettingsPasswordsPage />,
          },
          {
            path: "notification",
            element: <SettingsNotificationPage />,
          },
          {
            path: "others",
            element: <SettingsOthersPage />,
          },
        ],
      },
      {
        path: "/create",
        children: [
          {
            path: "",
            element: (
              <ProtectedRoute>
                <MainLayout>
                  <CreatePage />
                </MainLayout>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "sponsor-quiz",
        children: [
          {
            path: "create",
            element: (
              <ProtectedRoute>
                <MainLayout>
                  <SponsorQuizPage />
                </MainLayout>
              </ProtectedRoute>
            )
          }
        ],
      },
      {
        path: "/login",
        element: (
          <NonProtectedRoute>
            <AuthLayout>
              <Login />
            </AuthLayout>
          </NonProtectedRoute>
        ),
      },
      {
        path: "/email-verification",
        element: (
          <NonProtectedRoute>
            <AuthLayout>
              <EmailVerification />
            </AuthLayout>
          </NonProtectedRoute>
        ),
      },
      {
        path: "/forgot-password",
        element: (
          <NonProtectedRoute>
            <AuthLayout>
              <ForgotPasswordPage />
            </AuthLayout>
          </NonProtectedRoute>
        ),
      },
      {
        path: "/register",
        element: (
          <NonProtectedRoute>
            <AuthLayout>
              <RegisterPage />
            </AuthLayout>
          </NonProtectedRoute>
        ),
      },
      {
        path: "/reset/password",
        element: (
          <NonProtectedRoute>
            <AuthLayout>
              <ResetPasswordPage />
            </AuthLayout>
          </NonProtectedRoute>
        ),
      },
    ],
  },
]);

export default routes;
