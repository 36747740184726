import { PropsWithChildren } from "react";
import Header from "@/components/Header";
import Navigation from "@/components/Navigation";
import Profile from "@/components/Profile";
import Container from "@/components/Container";
import LogoutButton from "@/components/Logout";
import { useLayoutData } from "@/hooks/useLayoutData";

type MainLayoutProps = PropsWithChildren & {};

const MainLayout = ({ children }: MainLayoutProps) => {
  // const { newReports } = useLayoutData()

  // console.log({ newReports })

  return (
    <div className="flex min-h-screen transition bg-light-background-primary dark:bg-dark-background-primary">
      <div className="w-[320px] max-w-[320px] h-screen max-h-screen fixed">
        <div className="flex flex-col h-full px-8 pt-8 pb-6">
          <Navigation />

          <div className="mt-auto flex flex-col gap-7">
            <LogoutButton />
            <Profile />
          </div>
        </div>
      </div>

      <div className="flex-1 pl-[320px]">
        <Container>
          <Header />
          <div>{children}</div>
        </Container>
      </div>
    </div>
  );
};

export default MainLayout;
