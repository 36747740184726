import axios from "axios";
import { deleteTokens, getAccessToken, getRefreshToken, setTokens } from "./utils/auth";
import { updateAccessToken } from "./services/auth";
import { redirect } from "react-router-dom";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Accept-Language": "az",
    "X-App-Version": "1.0.0", // env
    "X-Device-Type": "WEB",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Avoid sending token when updating token
      if (
        config.url !== "api/customer-management-ms/v1/management/token/refresh"
      ) {
        const token = getAccessToken();

        if (token && config.headers) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
      }

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();

    if (
      error.response.status === 401 &&
      error.response.data.code === "ONSUAL-EXCEPTION-0037" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newTokenResp = await updateAccessToken(refreshToken);

        if (newTokenResp.data) {
          const { accessToken, refreshToken } = newTokenResp.data;

          setTokens({
            accessToken,
            refreshToken,
          });

          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (e) {
        if ((e as any).response.status === 403) {
          deleteTokens();

          return redirect("/login");
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
