import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const Star06 = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="#62717C" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.58325 1.66683C4.58325 1.20659 4.21016 0.833496 3.74992 0.833496C3.28968 0.833496 2.91658 1.20659 2.91658 1.66683V2.91683H1.66659C1.20635 2.91683 0.833252 3.28993 0.833252 3.75016C0.833252 4.2104 1.20635 4.5835 1.66659 4.5835H2.91658V5.8335C2.91658 6.29373 3.28968 6.66683 3.74992 6.66683C4.21016 6.66683 4.58325 6.29373 4.58325 5.8335V4.5835H5.83325C6.29349 4.5835 6.66658 4.2104 6.66658 3.75016C6.66658 3.28993 6.29349 2.91683 5.83325 2.91683H4.58325V1.66683Z" />
      <path d="M4.58325 14.1668C4.58325 13.7066 4.21016 13.3335 3.74992 13.3335C3.28968 13.3335 2.91658 13.7066 2.91658 14.1668V15.4168H1.66659C1.20635 15.4168 0.833252 15.7899 0.833252 16.2502C0.833252 16.7104 1.20635 17.0835 1.66659 17.0835H2.91658V18.3335C2.91658 18.7937 3.28968 19.1668 3.74992 19.1668C4.21016 19.1668 4.58325 18.7937 4.58325 18.3335V17.0835H5.83325C6.29349 17.0835 6.66658 16.7104 6.66658 16.2502C6.66658 15.7899 6.29349 15.4168 5.83325 15.4168H4.58325V14.1668Z" />
      <path d="M11.611 2.20101C11.4873 1.87919 11.1781 1.66683 10.8333 1.66683C10.4884 1.66683 10.1792 1.87919 10.0555 2.20101L8.61031 5.9584C8.35998 6.60927 8.28131 6.79683 8.17371 6.94815C8.06575 7.09999 7.93308 7.23266 7.78124 7.34062C7.62992 7.44823 7.44236 7.52689 6.79149 7.77723L3.0341 9.22237C2.71228 9.34615 2.49992 9.65535 2.49992 10.0002C2.49992 10.345 2.71228 10.6542 3.0341 10.778L6.79149 12.2231C7.44236 12.4734 7.62992 12.5521 7.78124 12.6597C7.93308 12.7677 8.06575 12.9003 8.17371 13.0522C8.28131 13.2035 8.35998 13.3911 8.61032 14.0419L10.0555 17.7993C10.1792 18.1211 10.4884 18.3335 10.8333 18.3335C11.1781 18.3335 11.4873 18.1211 11.611 17.7993L13.0562 14.0419C13.3065 13.3911 13.3852 13.2035 13.4928 13.0522C13.6008 12.9003 13.7334 12.7677 13.8853 12.6597C14.0366 12.5521 14.2241 12.4734 14.875 12.2231L18.6324 10.778C18.9542 10.6542 19.1666 10.345 19.1666 10.0002C19.1666 9.65535 18.9542 9.34615 18.6324 9.22237L14.875 7.77723C14.2241 7.52689 14.0366 7.44823 13.8853 7.34062C13.7334 7.23266 13.6008 7.09999 13.4928 6.94815C13.3852 6.79683 13.3065 6.60927 13.0562 5.9584L11.611 2.20101Z" />
    </svg>
  )
}