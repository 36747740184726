import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const Trash03 = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M6.6665 2.49984C6.6665 2.0396 7.0396 1.6665 7.49984 1.6665H12.4998C12.9601 1.6665 13.3332 2.0396 13.3332 2.49984C13.3332 2.96007 12.9601 3.33317 12.4998 3.33317H7.49984C7.0396 3.33317 6.6665 2.96007 6.6665 2.49984Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.6665 4.99984C1.6665 4.5396 2.0396 4.1665 2.49984 4.1665H17.4998C17.9601 4.1665 18.3332 4.5396 18.3332 4.99984C18.3332 5.46007 17.9601 5.83317 17.4998 5.83317H16.6128L16.0781 13.8544C16.0361 14.4839 16.0014 15.0049 15.9391 15.4289C15.8743 15.8704 15.7715 16.2739 15.5573 16.6499C15.2238 17.2352 14.7209 17.7057 14.1147 17.9995C13.7253 18.1882 13.3159 18.264 12.871 18.2993C12.4438 18.3332 11.9217 18.3332 11.2907 18.3332H8.70898C8.07805 18.3332 7.55593 18.3332 7.12869 18.2993C6.68386 18.264 6.27447 18.1882 5.88505 17.9995C5.27885 17.7057 4.77589 17.2352 4.44244 16.6499C4.22824 16.2739 4.12541 15.8704 4.06059 15.4289C3.99833 15.0049 3.96361 14.4839 3.92165 13.8544L3.3869 5.83317H2.49984C2.0396 5.83317 1.6665 5.46007 1.6665 4.99984ZM8.33317 7.9165C8.79341 7.9165 9.1665 8.2896 9.1665 8.74984V12.9165C9.1665 13.3767 8.79341 13.7498 8.33317 13.7498C7.87293 13.7498 7.49984 13.3767 7.49984 12.9165V8.74984C7.49984 8.2896 7.87293 7.9165 8.33317 7.9165ZM12.4998 8.74984C12.4998 8.2896 12.1267 7.9165 11.6665 7.9165C11.2063 7.9165 10.8332 8.2896 10.8332 8.74984V12.9165C10.8332 13.3767 11.2063 13.7498 11.6665 13.7498C12.1267 13.7498 12.4998 13.3767 12.4998 12.9165V8.74984Z" />
    </svg>
  )
}