import useThemeSwitcher from "@/hooks/useThemeSwitcher";
import IconButton from "../Form/Button/IconButton";

const Header = () => {
  const { toggleTheme } = useThemeSwitcher();

  return (
    <header className="flex justify-end items-center py-[18px]">
      <div className="flex gap-3">
        <IconButton
          icon="moon"
          size="lg"
          onClick={() => {
            toggleTheme();
          }}
        />
        <IconButton icon="bell" size="lg" />
      </div>
    </header>
  );
};

export default Header;
