import { ReactNode } from "react"

type Props = {
  children?: ReactNode
}

export const ErrorMessage = (props: Props) => {
  const { children } = props

  return (
    <div className="px-4">
      <span className="text-sm font-normal text-light-source-error dark:text-dark-source-error">
        {children}
      </span>
    </div>
  )
}