
import { SVGProps } from "react";

type Props = SVGProps<SVGSVGElement>;

export const Upload01Icon = (props: Props) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" fill="#62717C" {...props}>
      <path d="M9.9107 1.91058C10.2361 1.58514 10.7638 1.58514 11.0892 1.91058L15.2559 6.07725C15.5813 6.40269 15.5813 6.93032 15.2559 7.25576C14.9304 7.5812 14.4028 7.5812 14.0774 7.25576L11.3333 4.51168L11.3333 12.4998C11.3333 12.9601 10.9602 13.3332 10.5 13.3332C10.0397 13.3332 9.66663 12.9601 9.66663 12.4998L9.66663 4.51168L6.92255 7.25576C6.59711 7.5812 6.06947 7.5812 5.74404 7.25576C5.4186 6.93032 5.4186 6.40269 5.74404 6.07725L9.9107 1.91058Z" />
      <path d="M2.99996 11.6665C3.4602 11.6665 3.83329 12.0396 3.83329 12.4998V13.4998C3.83329 14.2137 3.83394 14.6989 3.86459 15.074C3.89444 15.4393 3.94854 15.6262 4.01495 15.7565C4.17474 16.0701 4.42971 16.3251 4.74331 16.4848C4.87364 16.5513 5.06048 16.6054 5.42583 16.6352C5.8009 16.6659 6.28614 16.6665 6.99996 16.6665H14C14.7138 16.6665 15.199 16.6659 15.5741 16.6352C15.9394 16.6054 16.1263 16.5513 16.2566 16.4848C16.5702 16.3251 16.8252 16.0701 16.985 15.7565C17.0514 15.6262 17.1055 15.4393 17.1353 15.074C17.166 14.6989 17.1666 14.2137 17.1666 13.4998V12.4998C17.1666 12.0396 17.5397 11.6665 18 11.6665C18.4602 11.6665 18.8333 12.0396 18.8333 12.4998V13.5343C18.8333 14.2051 18.8333 14.7587 18.7965 15.2097C18.7582 15.6781 18.6761 16.1087 18.47 16.5131C18.1504 17.1403 17.6405 17.6503 17.0133 17.9699C16.6088 18.1759 16.1782 18.2581 15.7098 18.2963C15.2588 18.3332 14.7052 18.3332 14.0344 18.3332H6.96554C6.29474 18.3332 5.7411 18.3332 5.29011 18.2963C4.82169 18.2581 4.3911 18.1759 3.98666 17.9699C3.35945 17.6503 2.84952 17.1403 2.52994 16.5131C2.32386 16.1087 2.24173 15.6781 2.20346 15.2097C2.16661 14.7587 2.16662 14.2051 2.16663 13.5343V12.4998C2.16663 12.0396 2.53972 11.6665 2.99996 11.6665Z" />
    </svg>
  )
}